import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IEvent } from '../interfaces/IEvent';

@Injectable()
export class EventState {
  private nextEvents$ = new BehaviorSubject<IEvent[] | null>(null);

  private loading$ = new BehaviorSubject<boolean>(false);

  setNextEvents(events: IEvent[]): void {
    this.nextEvents$.next(events);
  }

  getLoading$(): Observable<boolean> {
    return this.loading$.asObservable();
  }

  getNextEvents(): IEvent[] | null {
    return this.nextEvents$.getValue();
  }

  getNextEvents$(): Observable<IEvent[] | null> {
    return this.nextEvents$.asObservable();
  }

  setLoading(value: boolean): void {
    this.loading$.next(value);
  }
}
