import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '../../../core/base/BaseApi';
import { IApi } from '../../../core/interrfaces/IApi';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { fixTZ } from '../../../services/timezone/timezone.fix';
import dayjs from 'dayjs';
import { API_NEW_BASE_URL } from '../../../core/tokens/new-base-url.token';
import { ScheduledEvent } from '../../../dataset/ScheduledEvent';

@Injectable()
export class EventApi extends BaseApi implements IApi {
  constructor(
    private http: HttpClient,
    @Inject(API_NEW_BASE_URL) private baseUrl: string
  ) {
    super();
  }

  buildBaseUrl(input: string): string {
    return this.baseUrl + '/' + input;
  }

  getNextEvents(): Observable<ScheduledEvent[]> {
    return this.http.get<ScheduledEvent[]>(this.buildBaseUrl('api/v2/events/next/events'), {
      params: this.params({
        time_zone: fixTZ(dayjs.tz.guess()),
        classes_only: true,
      }),
    });
  }

  delete(eventId: number): Observable<void> {
    return this.http.delete<void>(this.buildBaseUrl(`api/v2/events/${eventId}`));
  }
}
