import { Injectable } from '@angular/core';
import { EventApi } from './api/event.api';
import { EventState } from './state/event.state';
import { Observable, of } from 'rxjs';
import { IEvent } from './interfaces/IEvent';
import { switchMap, tap } from 'rxjs/operators';

@Injectable()
export class EventFacade {
  constructor(
    private eventApi: EventApi,
    private eventState: EventState
  ) {}

  loadNextEvents(): Observable<IEvent[]> {
    return this.eventApi.getNextEvents().pipe(tap(events => this.eventState.setNextEvents(events)));
  }

  deleteEvent(eventId: number): Observable<IEvent[]> {
    return this.eventApi.delete(eventId).pipe(
      switchMap(() => {
        const events = this.eventState.getNextEvents()?.filter(e => e.id !== eventId);
        if (events && events.length) {
          this.eventState.setNextEvents(events);
          return of(events);
        }
        return this.loadNextEvents();
      })
    );
  }

  get loading$(): Observable<boolean> {
    return this.eventState.getLoading$();
  }

  get nextEvents$(): Observable<IEvent[] | null> {
    return this.eventState.getNextEvents$();
  }

  get nextEvents(): IEvent[] | null {
    return this.eventState.getNextEvents();
  }

  startLoading(): void {
    return this.eventState.setLoading(true);
  }

  stopLoading(): void {
    return this.eventState.setLoading(false);
  }
}
